.search-container {
  .search-wrapper {
    &__results {
      .mpp-sort {
        .selectBox {
          width: 175px;
        }
      }
    }
  }
}

.mpp-container--has-filters {
  .mpp-sort {
    width: 60%;
    @include breakpoint($medium-up) {
      width: auto;
    }
    .selectBox-dropdown,
    .mpp-sort-menu {
      width: 218px;
      padding: 0 15px;
      @include breakpoint($medium-up) {
        width: 230px !important;
      }
    }
  }
  .mpp-filter {
    width: 40%;
    @include breakpoint($medium-up) {
      width: 12%;
    }
  }
}

.product-full {
  .product-add-to-waitlist {
    .button {
      display: inline-block;
    }
  }
  &__footer {
    .product-full {
      &__accordion__title {
        &::after {
          margin-#{$rdirection}: 0;
        }
      }
    }
  }
}

.waitlist-form__submit {
  margin-top: 20px;
}

.cart-block__footer__subtotal__value {
  padding-#{$rdirection}: 5px;
}

#onetrust-pc-sdk {
  #ot-fltr-modal {
    .ot-chkbox {
      label {
        &::before {
          top: 5px;
        }
      }
    }
  }
}

.spp_reviews {
  .js-mpp-summary-snippet {
    .p-w-r {
      .pr-snippet {
        margin-top: -18px;
      }
    }
  }
}

.email-signup-panel {
  &__title {
    line-height: 1;
  }
}

.pre-order-message-display {
  float: #{$ldirection};
  clear: both;
}
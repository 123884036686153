// signin page redesign
$signin-redesigned: true;
$cr22_footer_refresh: true;

// Creative Refresh 2022 (cr22)
$cr22: true;
$max-width: 1440px;
$filter-block-width: $max-width;
$container-max-width: $max-width;
$container-max-width-landscape: $max-width;
$content-block-max-width: $max-width;

.checkout-panel {
  &--viewcart {
    .go-shopping--btn {
      @include breakpoint($medium-up) {
        width: auto;
      }
    }
    .cart-items__item--remove {
      .remove_link--pc {
        display: none;
        @include breakpoint($medium-up) {
          display: block;
          position: relative;
        }
      }
      .remove_link.pc_hidden {
        display: block;
        @include breakpoint($medium-up) {
          display: none;
        }
      }
    }
  }
  &--offer-code {
    .button {
      padding: 10px 20px;
    }
  }
  &--gift-options {
    .button {
      padding: 10px 20px;
      margin-top: 25px;
    }
    .gift-options-content {
      &--header {
        .icon {
          #{$rdirection}: 0;
          @include breakpoint($medium-up) {
            #{$rdirection}: 20px;
          }
        }
      }
    }
  }
  &--confirmation {
    padding-bottom: 0;
    .checkout-panel {
      &__heading {
        line-height: 1.6;
      }
    }
    .confirmation-content {
      &__print-buttons {
        width: 100%;
        margin-bottom: 30px;
        float: none;
        .button {
          width: 100%;
          @include breakpoint($medium-up) {
            width: 40%;
          }
        }
      }
      &__actions {
        margin-bottom: 15px;
      }
    }
  }
  &--registration {
    .registration-content {
      &__heading {
        font-weight: bold;
        font-family: $font--text;
      }
    }
  }
}

.sticky-checkout-button {
  .continue-checkout {
    padding: 10px 20px;
  }
}

.checkout {
  &__sidebar {
    .viewcart-buttons-panel {
      border-bottom: 1px solid $color-light-gray;
      margin: 0 1em;
      padding: 1em 0;
      .continue-buttons {
        .btn {
          border-radius: 10px;
          display: block;
          font-size: 12px;
          margin-#{$ldirection}: auto;
          margin-#{$rdirection}: auto;
          margin-top: 7px;
          position: relative;
          width: 85%;
          @include breakpoint($medium-up) {
            width: 325px;
          }
        }
        .btn-primary {
          background-color: $color-dark-gray;
          color: $color-white;
          height: 40px;
          line-height: 3.5;
          text-transform: uppercase;
          text-decoration: none;
          @include breakpoint($medium-up) {
            font-weight: bold;
          }
        }
        .gwp-paypal-checkout,
        .paypal-checkout {
          background-color: $color-white;
          @include breakpoint($medium-up) {
            background-image: url('/media/images/logos/paypal.gif');
            background-size: 80px;
            background-repeat: no-repeat;
          }
        }
        .gwp-paypal-checkout {
          @include breakpoint($medium-up) {
            border: 1px solid $color-dark-gray;
            background-position: 180px;
            height: 40px;
            font-weight: bold;
            line-height: 3.5;
            text-decoration: none;
            text-align: #{$ldirection};
            text-indent: 60px;
          }
        }
        .paypal-checkout {
          @include breakpoint($medium-up) {
            background-position: 200px;
          }
        }
      }
    }
  }
}

.invoice_container {
  .messages {
    padding: 0;
    border: none;
  }
  .form-container {
    .form-item {
      @include swap_direction(margin, 0.5em 0.5em 0.5em 0);
      width: 100%;
      display: inline-block;
      @include breakpoint($medium-up) {
        width: 40%;
      }
      .field {
        width: 100%;
      }
    }
  }
  .continue-button-wrapper {
    margin-top: 20px;
    width: auto;
  }
}

.invoice_wrapper,
.invoice__thanks {
  margin: 30px auto 100px;
  max-width: 940px;
  padding: 0 20px;
  @include breakpoint($medium-up) {
    padding: 0;
  }
}

.samples-content {
  .offer_heading {
    font-size: 21px;
  }
}

.address-book-page__overlay {
  .address-form {
    .form-field {
      &.postalcode_state-field {
        @include breakpoint($medium-up) {
          width: 49%;
        }
        input[type='text'] {
          width: 100%;
        }
      }
    }
    .ship_type-field {
      .form-item {
        width: 50%;
        float: #{$ldirection};
        margin-bottom: 0;
      }
    }
    .field {
      font-size: 13px;
    }
  }
}

#password_request,
#password_reset {
  .submit {
    .button,
    .form-submit {
      height: auto;
      border-radius: 0;
    }
  }
}

.account-orders-list {
  .order-details-page__content {
    @include breakpoint($medium-up) {
      float: none;
    }
  }
}

.sign-in-page {
  .new-account {
    .form-item {
      &.email-promo {
        .label-content {
          font-size: 13px;
        }
      }
    }
  }
}

.address-info {
  .address-item {
    &__controls {
      display: flex;
      justify-content: $rdirection;
      flex-direction: column;
      font-size: 0; // hide text outside tags
      a, span {
        cursor: pointer;
        margin: 5px 5px 0;
        font-weight: 700;
        text-align: right;
        text-decoration: underline;
        font-size: 15px;
      }
      @include breakpoint($medium-up) {
        flex-direction: row;
        font-size: 15px; // show text outside tags
        a, span {
          margin: 0 5px;
          text-align: center;
        }
      }
    }
  }
}
